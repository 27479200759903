import './css/normalize.scss';
import './css/fonts.scss';
import './css/core.scss';

import Vue from 'vue';
import VueConfetti from 'vue-confetti';
import axios from 'axios';
import VueAxios from 'vue-axios';
import VueCookies from 'vue-cookies';
import App from './App.vue';

Vue.config.productionTip = false;
Vue.use(VueAxios, axios);
Vue.use(VueConfetti);
Vue.use(VueCookies);
Vue.$cookies.config('7d');

// set global cookie
Vue.$cookies.set('theme', 'default');
Vue.$cookies.set('hover-time', '1s');

new Vue({
  render: (h) => h(App),
}).$mount('#app');
